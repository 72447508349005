import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'groups', sortable: true, label: 'Primary Group' },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    store
      .dispatch('userStoreModule/fetchUsers', {
        search: searchQuery.value,
        limit: perPage.value,
        // sortDesc: isSortDirDesc.value,
        page: currentPage.value,
        sortBy: sb,

        populate: 'groups',
        role: roleFilter.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(
          results.map((user) => {
            user.groups = user.groups.filter((group) => group.enabled).filter((group) => !group.hidden);

            if (user.groups.length === 0) {
              user.group = { name: 'no groups', metadata: { icon: 'fa-solid fa-user', color: 'danger' } };
              return user;
            }

            const h = user.groups.sort((a, b) => b.priority - a.priority)[0];
            user.group = h;

            return user;
          }),
        );
        totalUsers.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'student') return 'info';
    if (role === 'staff') return 'warning';
    if (role === 'proctor') return 'info';
    if (role === 'accountant') return 'success';
    if (role === 'auditor') return 'info';
    if (role === 'admin' || role === 'manager') return 'danger';
    return 'info';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'student') return 'UserIcon';
    if (role === 'staff') return 'SettingsIcon';
    if (role === 'proctor') return 'BookIcon';
    if (role === 'accountant') return 'DollarSignIcon';
    if (role === 'auditor') return 'BookIcon';
    if (role === 'manager') return 'Edit2Icon';
    if (role === 'admin') return 'DatabaseIcon';
    return 'UserIcon';
  };

  const resolveUserStatusVariant = (status) => {
    if (status === 'pending') return 'warning';
    if (status === 'active') return 'success';
    if (status === 'inactive') return 'secondary';
    return 'info';
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
